import Swal from 'sweetalert2'
import capitalize from 'capitalize-pt-br'
import { useSelector, useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FaPlus, FaSearch } from 'react-icons/fa'
import { BiFilter } from 'react-icons/bi'
import { Button, Loading, Tab, Table, TableColumnProps, FiltroPedidos, DropList, ListaFretes, Modal, Textarea } from '..'
import { Cliente } from '../../../modules/clientes/models'
import { Empresa } from '../../../modules/empresas/models/empresa'
import { GetPedido, GetPedidoCotacoesFretes, TipoFaturamentoPedido } from '../../../modules/pedidos/models'
import { useCliente, useExecUseCase, useFuncionario, usePedido, useUsuario } from '../../hooks'
import { useListaPedidos } from '../../hooks/pedido/useListaPedido'
import { TableActions, TableText, TextStatus } from '../../styles/global'
import { Pedido } from '../Pedido'
import { Container, Content, PedidosAbertos, HeaderTablePedidos, TabFixaPedidos } from './styles'
import { AppState } from '../../store'
import { abrirPedido, fecharPedido, iniciarPedido, setPedidoEmFoco } from '../../store/atendimentos/actions'
import { AtendimentoType, IniciarPedidoParams, PedidoEmFocoType } from '../../store/atendimentos/interfaces'
import { TextStatusPedido } from '../../pages/Pedidos/styles'
import { AcoesPedidos } from '../AcoesPedidos'
import { PostParams } from '../../../modules/_interfaces'
import { makeAtualizarPedido, makeBloquearDesconto, makeBloquearEstoqueZerado, makeCancelarPedido, makeDesmembrarPedido, makeLiberarEstoquePedido, makeNovaSolicitacao, makeProcessarFretePedido, makeTransformarPedido } from '../../../modules/pedidos/factories'
import { calcTotalListaPedidos, canUpdatePedido, checkPedidoStatus, convertStringToDate } from '../../../helpers'
import { SortTableProps } from '../../../modules/_interfaces/sortTable'
import { FilterPedidoProps } from '../../../modules/_interfaces/filtersProps'
import { makeCancelarLiberarEstoquePedido } from '../../../modules/pedidos/factories/cancelar-liberar-estoque-pedido'
import { differenceInDays, format, startOfMonth, subMonths } from 'date-fns'
import { UsuariosState } from '../../store/usuarios/interfaces'
import { usePedidoProcessarFrete } from '../../hooks/pedido/usePedidoProcessarFrete'
import { ListaFretesManual } from '../ListaFretesManual'
import { makeTrazerEquipePorId } from '../../../modules/equipes/factories'
import { DesmembrarPedido } from '../DesmembrarPedido'
import { makeTrazerEmpresaPorId } from '../../../modules/empresas/factories'
import { NovaSolicitacao } from '../NovaSolicitacao'

const perPage = 20

interface PedidosProps {
  cliente: Cliente
}

export const Pedidos = ({ cliente }: PedidosProps) => {
  const dispatch = useDispatch()
  const { executeUseCase, executing: executingAtualizaPedido } = useExecUseCase()
  const usuarioState = useSelector<AppState>(state => state.usuarios) as UsuariosState
  const atendimento = useSelector<AppState>(state => state?.atendimentos?.data[cliente.id as number]) as AtendimentoType
  const [currentPage, setCurrentPage] = useState(0)
  const { data: dataFuncionario } = useFuncionario(cliente?.funcionario_fidelizado as number)
  const { data: usuario } = useUsuario()
  const { fetcher: getPedido, data: pedidoSelecionado, loading: loadingPedido } = usePedido()
  const { fetcher: getPedidoFretes, data: pedidoFretes, loading: loadingPedidoFretes } = usePedidoProcessarFrete()
  const { fetcher: getCliente } = useCliente()
  const [dropIsOpen, setDropIsOpen] = useState(false)
  const [pedidoIdEmEdicao, setPedidoIdEmEdicao] = useState(0)
  const [showDesmembrarPedido, setShowDesmembrarPedido] = useState(false)
  const [showListaFretes, setShowListaFretes] = useState(false)
  const [showListaFretesManual, setShowListaFretesManual] = useState(false)
  const [showSolicitacoes, setShowSolicitacoes] = useState(false)
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  const [filters, setFilters] = useState<FilterPedidoProps>({
    search: '',
    tipo: '',
    liberadoEstoque: '',
    estoque: '',
    liberacao: '',
    dataInicial: format(startOfMonth(subMonths(new Date(), 1)), 'yyyyMMdd'),
    dataFinal: format(new Date(), 'yyyyMMdd'),
    exibir: 'todos'
  } as FilterPedidoProps)
  const [sortTable, setSortTable] = useState<SortTableProps>({
    id: 'updated_at',
    desc: true,
    header: 'updated_at'
  } as SortTableProps)

  useEffect(() => {
    if (pedidoSelecionado?.data?.id) {
      dispatch(abrirPedido({
        pedido: pedidoSelecionado?.data
      }))

      setPedidoIdEmEdicao(0)
    }
  }, [dispatch, pedidoSelecionado])

  const { fetcher: getListaPedidos, data: pedidosCliente, loading: loadingListaPedido } = useListaPedidos()
  const { fetcher: getListaPedidosRevenda, data: pedidosRevenda, loading: loadingListaPedidoRevenda } = useListaPedidos()
  const [pedidos, setPedidos] = useState<GetPedido[]>([])
  const [pedidoEdicao, setPedidoEdicao] = useState<GetPedido>({} as GetPedido)
  const [solicitacaoEdicao, setSolicitacaoEdicao] = useState<any>()

  const erroDiffDays = () => {
    Swal.fire({
      title: 'Periodo muito extenso!',
      text: 'O periodo escolhido é maior que 100 dias, tente novamente com uma data menor',
      icon: 'warning'
    })
  }

  const handleSearch = useCallback(() => {
    const dataInicial = convertStringToDate(filters.dataInicial)
    const dataFinal = convertStringToDate(filters.dataFinal)
    const diffDate = differenceInDays(dataFinal, dataInicial)
    if (diffDate > 100) {
      erroDiffDays()
    } else {
      getListaPedidos({
        perPage,
        currentPage: currentPage + 1,
        filters,
        sortTable,
        cliente_id: cliente.id as number
      })
      if (cliente.is_revenda === 's') {
        getListaPedidosRevenda({
          perPage,
          currentPage: currentPage + 1,
          filters,
          sortTable,
          revenda_id: cliente.id as number
        })
      }
    }
  }, [cliente, currentPage, filters, getListaPedidos, getListaPedidosRevenda, sortTable])

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let newPedidosList: GetPedido[] = []
    if (pedidosCliente && pedidosCliente.data) {
      newPedidosList = newPedidosList.concat(pedidosCliente.data)
    }
    if (pedidosRevenda && pedidosRevenda.data && pedidosRevenda.data.length > 0) {
      newPedidosList = newPedidosList.concat(pedidosRevenda.data)
    }
    newPedidosList.sort(function (a, b) {
      if (a.updated_at && b.updated_at) {
        if (a.updated_at < b.updated_at) {
          return 1
        }
        if (a.updated_at > b.updated_at) {
          return -1
        }
        return 0
      } else {
        return 0
      }
    })
    setPedidos(newPedidosList)
  }, [pedidosCliente, pedidosRevenda])

  const canChangePedido = useCallback(async (pedido: GetPedido) => {
    if (pedido) {
      const canUpdate = await canUpdatePedido(pedido.id, pedido?.updated_at, usuario?.token)
      if (!canUpdate) {
        Swal.fire({
          icon: 'warning',
          title: 'Não foi possivel concluir a ação',
          text: 'Esse pedido possui dados atualizados, recarregue a lista e tente novamente!'
        })
      }
      return canUpdate
    } else {
      return false
    }
  }, [usuario])

  const reloadList = useCallback(() => {
    setCurrentPage(0)

    getListaPedidos({
      perPage,
      currentPage: 1,
      filters,
      sortTable,
      cliente_id: cliente.id as number
    })
  }, [cliente.id, filters, getListaPedidos, sortTable])

  const handleGerarPdf = useCallback((pedido: GetPedido) => {
    const podeSalvarPedidoData = canChangePedido(pedido)
    if (!podeSalvarPedidoData) {
      reloadList()
      return
    }
    if (pedido.id) {
      const url = '/gerar-pdf/' + pedido.id + '/' + cliente.id
      window.open(url, '_blank')
    }
  }, [canChangePedido, cliente, reloadList])

  const handleTransformarPedido = useCallback((pedido: GetPedido) => {
    const podeSalvarPedidoData = canChangePedido(pedido)
    if (!podeSalvarPedidoData) {
      reloadList()
      return
    }

    if (pedido.tipo === 'pedido') {
      return
    }

    const params = {
      id: pedido?.id
    }

    const useCase = makeTransformarPedido()
    executeUseCase<any>(
      useCase,
      params,
      'Orçamento transformado em pedido!',
      `Essa ação irá transformar o orçamento ${pedido?.id} em pedido, realmente deseja prosseguir?`,
      reloadList,
      true
    )
  }, [canChangePedido, executeUseCase, reloadList])

  const handleCancelarPedido = useCallback((pedido: GetPedido) => {
    const podeSalvarPedidoStatus = checkPedidoStatus(pedido.status as string)
    const podeSalvarPedidoData = canChangePedido(pedido)
    if (!podeSalvarPedidoStatus || !podeSalvarPedidoData) {
      reloadList()
      return
    }
    const params = {
      id: pedido?.id
    }

    const useCase = makeCancelarPedido()
    executeUseCase<any>(
      useCase,
      params,
      'Pedido cancelado com sucesso!',
      `Essa ação irá cancelar o pedido ${pedido?.id}, realmente deseja prosseguir ?`,
      reloadList,
      true
    )
  }, [canChangePedido, executeUseCase, reloadList])

  const handleAbrirPedido = useCallback((pedido: GetPedido) => {
    if (pedido) {
      setPedidoIdEmEdicao(pedido.id as number)
      getPedido(pedido.id)
    }
  }, [getPedido])

  const handleProcessarFrete = useCallback(async (pedido: GetPedido) => {
    const podeSalvarPedidoStatus = checkPedidoStatus(pedido.status as string)
    const podeSalvarPedidoData = canChangePedido(pedido)

    if (!podeSalvarPedidoStatus || !podeSalvarPedidoData) {
      reloadList()
      return
    }

    if (!pedido.id) {
      return null
    }

    const equipe = await executeUseCase<any>(
      makeTrazerEquipePorId(),
      {
        filter: String(pedido?.equipe?.id)
      },
      '',
      '',
      undefined,
      false
    )

    const empresa = await executeUseCase<any>(
      makeTrazerEmpresaPorId(),
      {
        filter: String(pedido?.deposito?.id)
      },
      '',
      '',
      undefined,
      false
    )

    const frete_equipe = equipe?.frete_automatico ?? 'n'
    const frete_empresa = empresa?.data?.frete_automatico ?? 'n'

    if (frete_equipe === 's' && frete_empresa === 's') {
      getPedidoFretes(pedido.id)
      setShowListaFretes(true)
      return
    }

    setPedidoIdEmEdicao(pedido.id)
    setShowListaFretesManual(true)
  }, [canChangePedido, executeUseCase, getPedidoFretes, reloadList])

  const handleProcessarFreteEstacionamento = useCallback(async (pedido: GetPedido) => {
    const podeSalvarPedidoStatus = checkPedidoStatus(pedido.status as string)
    const podeSalvarPedidoData = canChangePedido(pedido)

    if (!pedido.id || !podeSalvarPedidoStatus || !podeSalvarPedidoData) {
      reloadList()
      return null
    }

    const useCase = makeProcessarFretePedido()
    executeUseCase<PostParams>(
      useCase,
      {
        body: {
          pedido_id: pedido?.id,
          tipo: 'estacionamento'
        }
      },
      'Pedido atualizado com sucesso!',
      `Essa ação irá processar o frete do pedido ${pedido?.id} para estacionamento, deseja prosseguir?`,
      reloadList
    )
  }, [canChangePedido, executeUseCase, reloadList])

  const handleDesmembrarPedido = useCallback(async (pedido: GetPedido) => {
    const podeSalvarPedidoStatus = checkPedidoStatus(pedido.status as string)
    const podeSalvarPedidoData = canChangePedido(pedido)

    if (!podeSalvarPedidoStatus || !podeSalvarPedidoData) {
      reloadList()
      return
    }

    if (!pedido.id) {
      return null
    }
    setPedidoIdEmEdicao(pedido.id)
    setShowDesmembrarPedido(true)
  }, [canChangePedido, reloadList])

  const handleSolicitacoes = useCallback(async (pedido: GetPedido) => {
    if (!pedido.id) {
      return null
    }

    setPedidoIdEmEdicao(pedido.id)
    setShowSolicitacoes(true)
  }, [])

  const handleLiberarEstoque = useCallback((pedido: GetPedido, valor: string) => {
    const podeSalvarPedidoStatus = checkPedidoStatus(pedido.status as string)
    const podeSalvarPedidoData = canChangePedido(pedido)
    if (!podeSalvarPedidoStatus || !podeSalvarPedidoData) {
      reloadList()
      return
    }

    const params = {
      body: {
        pedidoId: pedido?.id
      },
      token: usuario ? usuario.token : ''
    }

    if (valor === 's') {
      const useCase = makeLiberarEstoquePedido()
      executeUseCase<PostParams>(
        useCase,
        params,
        'Pedido atualizado com sucesso!',
        `Essa ação irá liberar o pedido ${pedido?.id} para o estoque, realmente deseja prosseguir ?`,
        reloadList
      )
    } else {
      const useCase = makeCancelarLiberarEstoquePedido()
      executeUseCase<PostParams>(
        useCase,
        params,
        'Pedido atualizado com sucesso!',
        `Essa ação irá cancelar a liberacao do pedido ${pedido?.id} para o estoque, realmente deseja prosseguir ?`,
        reloadList
      )
    }
  }, [canChangePedido, usuario, reloadList, executeUseCase])

  // const handleMultChangePedido = useCallback(async (questionMessage: string, status: string, id: number, campo: string[], valor: string[] | number[]) => {
  //   const podeSalvarPedido = checkPedidoStatus(status as string)
  //   if (!podeSalvarPedido) {
  //     return
  //   }

  //   const useCase = makeAtualizarPedido()
  //   executeUseCase<PostParams>(
  //     useCase,
  //     params,
  //     'Pedido atualizado com sucesso!',
  //     questionMessage
  //   )
  //   reloadList()
  // }, [executeUseCase, reloadList, usuario])

  const handleTabCloseOnClick = useCallback(async (pedido: PedidoEmFocoType) => {
    let pedidoEmEdicao = false

    if (pedido !== 'em_andamento') {
      pedidoEmEdicao = atendimento.pedidosEmAberto.data[pedido as number]?.emEdicao && Object.keys(atendimento.pedidosEmAberto.data[pedido as number]?.emEdicao).length > 0
    }

    if (pedido === 'em_andamento' || pedidoEmEdicao) {
      let questionResponse = await Swal.fire({
        text: pedido === 'em_andamento'
          ? 'Esse orçamento não foi salvo. Finalizar mesmo assim?'
          : 'O orçamento foi editado mas não foi salvo. Finalizar mesmo assim?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Fechar sem salvar',
        cancelButtonText: 'Cancelar'
      })

      if (questionResponse?.isConfirmed) {
        dispatch(fecharPedido({
          clienteId: cliente.id as number,
          pedido
        }))
      }
    } else {
      dispatch(fecharPedido({
        clienteId: cliente.id as number,
        pedido
      }))
    }
  }, [atendimento.pedidosEmAberto, cliente.id, dispatch])

  const bloquearDesconto = useCallback((pedido: GetPedido, mensagem: string) => {
    const params = {
      id: pedido?.id
    }

    const useCase = makeBloquearDesconto()

    executeUseCase<any>(
      useCase,
      params,
      'Pedido cancelado com sucesso!',
      mensagem,
      reloadList,
      true
    )
  }, [executeUseCase, reloadList])

  const bloquearEstoqueZerado = useCallback((pedido: GetPedido, mensagem: string) => {
    const params = {
      id: pedido?.id
    }

    const useCase = makeBloquearEstoqueZerado()

    executeUseCase<any>(
      useCase,
      params,
      'Pedido cancelado com sucesso!',
      mensagem,
      reloadList,
      true
    )
  }, [executeUseCase, reloadList])

  const handleBloquearPedido = useCallback((pedido: GetPedido, modo: string) => {
    const podeSalvarPedidoStatus = checkPedidoStatus(pedido.status as string)
    const podeSalvarPedidoData = canChangePedido(pedido)
    if (!podeSalvarPedidoStatus || !podeSalvarPedidoData) {
      reloadList()
      return
    }

    let mensagem = ''

    if (modo === 'estoque_zerado') {
      mensagem = `Essa ação irá bloquear o pedido ${pedido?.id}, referente ao estoque, realmente deseja prosseguir ?`
      bloquearEstoqueZerado(pedido, mensagem)
    } else if (modo === 'desconto') {
      mensagem = `Essa ação irá bloquear o pedido ${pedido?.id}, referente ao desconto, realmente deseja prosseguir ?`
      bloquearDesconto(pedido, mensagem)
    }
  }, [bloquearDesconto, bloquearEstoqueZerado, canChangePedido, reloadList])

  const columns: TableColumnProps[] = useMemo(() => [
    {
      Header: '#',
      accessor: 'id',
      minWidth: 7,
      Cell: ({ value }) => {
        return <TableText position='right'>{value}</TableText>
      }
    },
    {
      Header: 'Tipo',
      accessor: 'tipo',
      minWidth: 7,
      Cell: ({ value }) => {
        return <TableText position='left'>{capitalize(value)}</TableText>
      }
    },
    {
      Header: 'Depósito',
      accessor: 'deposito',
      minWidth: 15,
      Cell: (cell) => {
        const deposito: Empresa = cell.value as Empresa
        return `${capitalize(deposito.nome as string)}/${deposito.uf?.toUpperCase()}`
      }
    },
    {
      Header: 'Cliente',
      accessor: 'cliente',
      minWidth: 25,
      Cell: (cell) => {
        const cliente: Cliente = cell.value as Cliente
        return `${cliente.id} - ${capitalize(cliente.nome_fantasia as string)}`
      }
    },
    {
      Header: 'Transportadora',
      accessor: 'transportadora',
      minWidth: 25,
      Cell: (cell) => {
        const transportadora: Cliente = cell.value as Cliente
        return `${transportadora.id} - ${capitalize(transportadora.nome_fantasia as string)}`
      }
    },
    {
      Header: 'Liberado Estoque',
      accessor: 'liberado',
      minWidth: 12,
      Cell: ({ value }) => {
        const status = value === 'n' ? 'Não' : 'Sim'
        return <TextStatus status={value}>{status}</TextStatus>
      }
    },
    {
      Header: 'Analise Cred.',
      accessor: 'analise_credito',
      minWidth: 8,
      Cell: ({ value }) => {
        return <TableText position='center'>{capitalize(value)}</TableText>
      }
    },
    {
      Header: 'Situação',
      accessor: 'status',
      Cell: ({ value }) => <TextStatusPedido status={value}>{capitalize(value)}</TextStatusPedido>,
      minWidth: 10
    },
    {
      Header: 'Data',
      accessor: 'data',
      Cell: ({ row }) => {
        const pedido = row._original
        return (
          <span>{pedido.data && pedido.hora ? `${pedido.data} ${pedido.hora}` : ''}</span>
        )
      },
      minWidth: 10
    },
    {
      Header: 'Doc. Fiscal',
      accessor: 'documento_fiscal',
      minWidth: 8,
      Cell: ({ value }) => <TableText position='center'>{value}</TableText>
    },
    {
      Header: 'Total (R$)',
      minWidth: 10,
      Cell: ({ row }) => {
        return <TableText position='right'>{calcTotalListaPedidos(row._original)}</TableText>
      }
    },
    {
      Header: 'Ações',
      minWidth: 7,
      Cell: ({ row }) => {
        const pedido = row._original as GetPedido
        return (
          <TableActions>
            <AcoesPedidos
              data-cy={'actionPedido' + row._original.id}
              pedido={pedido}
              callbackOpen={handleAbrirPedido}
              handleProcessarFrete={handleProcessarFrete}
              handleProcessarFreteEstacionamento={handleProcessarFreteEstacionamento}
              handleLiberarEstoque={handleLiberarEstoque}
              // callbackMultChange={handleMultChangePedido}
              handleGerarPdf={handleGerarPdf}
              handleTransformarPedido={handleTransformarPedido}
              handleDesmembrarPedido={handleDesmembrarPedido}
              handleSolicitacoes={handleSolicitacoes}
              handleCancelarPedido={handleCancelarPedido}
              handleBloquearPedido={handleBloquearPedido}
            />
          </TableActions>
        )
      }
    }
  ], [handleAbrirPedido, handleProcessarFrete, handleProcessarFreteEstacionamento, handleLiberarEstoque, handleGerarPdf, handleTransformarPedido, handleDesmembrarPedido, handleSolicitacoes, handleCancelarPedido, handleBloquearPedido])

  const handleOnPageChange = useCallback((page: number) => {
    if (!pedidosCliente?.metadata?.count) {
      return
    }

    setCurrentPage(page)
    getListaPedidos({
      perPage,
      currentPage: page + 1,
      filters,
      sortTable,
      cliente_id: cliente.id as number
    })
  }, [cliente, filters, getListaPedidos, pedidosCliente, sortTable])

  const handleClickDropList = useCallback(() => {
    if (cliente.is_revenda_cliente === 's') {
      Swal.fire({
        icon: 'warning',
        title: 'Cliente revenda!',
        text: 'Não é possivel fazer pedido para cliente revenda.'
      })
    } else {
      setDropIsOpen(state => !state)
    }
  }, [cliente])

  const handleNovoOrcamentoOnClick = useCallback(async (type: TipoFaturamentoPedido) => {
    if (cliente.status === 'inativo') {
      Swal.fire({
        icon: 'warning',
        title: 'Cliente Inativo!',
        text: 'Não é possivel abrir novos pedidos para clientes inativos.'
      })
      return
    }

    handleClickDropList()

    let solar: 's' | 'n' = 'n'
    if (type === 'projeto_direto_solar') {
      type = 'direto'
      solar = 's'
    } else if (type === 'projeto_solar') {
      type = 'normal'
      solar = 's'
    }

    let iniciarPedidoParams: IniciarPedidoParams = {
      cliente: cliente,
      abaAtiva: 'dadosgerais',
      tipo_faturamento: type,
      solar,
      funcionario: {
        id: usuarioState?.funcionario_id || usuario?.funcionario_id,
        nome: usuarioState?.nome || usuario?.nome,
        empresa_id: parseInt(usuarioState?.empresa_id || usuario?.empresa_id as string)
      }
    }

    if (solar === 's') {
      iniciarPedidoParams = {
        ...iniciarPedidoParams,
        funcionario: {
          id: usuarioState.funcionario_id,
          nome: usuarioState.nome,
          empresa_id: Number(usuarioState.empresa_id)
        }
      }
    } else {
      if (cliente.funcionario_fidelizado && cliente.funcionario_fidelizado > 0) {
        if (cliente.funcionario_fidelizado !== parseInt(usuario?.funcionario_id as string)) {
          if (dataFuncionario && dataFuncionario.data) {
            iniciarPedidoParams = {
              ...iniciarPedidoParams,
              funcionario: dataFuncionario?.data
            }
          }
        }
      }
    }

    const transportadora = await getCliente({ clienteId: 10005 })

    // define a transportadora
    iniciarPedidoParams = {
      ...iniciarPedidoParams,
      transportadora
    }

    dispatch(iniciarPedido(iniciarPedidoParams))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliente, dataFuncionario, dispatch, getCliente, handleClickDropList])

  const handleTabOnClick = useCallback((pedidoEmFoco: PedidoEmFocoType) => {
    dispatch(setPedidoEmFoco({
      clienteId: cliente.id as number,
      pedidoEmFoco
    }))
  }, [cliente.id, dispatch])

  const handleFilterOpen = () => {
    setFilterIsOpen(!filterIsOpen)
  }

  const handleOnSortedChange = (header: any) => {
    const sort = {
      id: header[0].id,
      desc: header[0].desc
    }
    if (sort.id) {
      switch (sort.id) {
        case 'cliente':
          sort.id = 'clientes.nome_fantasia'
          break
        case 'transportadora':
          sort.id = 'pedidos.transportadora_id'
          break
        case 'empresa':
          sort.id = 'empresas.nome'
          break
        case 'deposito':
          sort.id = 'dep.nome'
          break
        case 'liberado':
          sort.id = 'pedidos.liberado'
          break
        case 'prazo':
          sort.id = 'pedidos.prazo_dias'
          break
        default:
          sort.id = 'pedidos.' + sort.id
          break
      }
      setSortTable(sort)
    }
  }

  const handleOnSaveDesmembrarPedido = async () => {
    if (!pedidoEdicao) {
      return null
    }

    let total = 0

    pedidoEdicao.itens?.forEach(p => {
      total += p.quantidade
    })

    if (total < 1) {
      await Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Não é possível desmembrar todos os produtos.',
        showConfirmButton: true,
        showCancelButton: false
      })

      return
    }

    const refresh = () => {
      reloadList()
      setShowDesmembrarPedido(false)
    }

    const params = {
      body: {
        ...pedidoEdicao,
        id: pedidoEdicao.id
      }
    }

    const desmembrarPedido = makeDesmembrarPedido()
    const response = await executeUseCase<PostParams>(
      desmembrarPedido,
      params,
      '',
      'Deseja desmembrar o pedido?',
      () => { refresh() },
      false)

    if (response) {
      await Swal.fire({
        icon: 'success',
        title: 'Novo pedido',
        text: `Novo pedido salvo com o id ${response.novo_id}`,
        showConfirmButton: true,
        showCancelButton: false
      })
    }
  }

  const handleCallbackDesmembrarPedido = useCallback((pedido: GetPedido) => {
    if (!pedido) {
      return null
    }

    setPedidoEdicao(pedido)
  }, [])

  const handleCallbackListaFretes = useCallback((frete: GetPedidoCotacoesFretes) => {
    if (!frete) {
      return null
    }
    reloadList()
    setShowListaFretes(false)
  }, [reloadList])

  const handleCallbackListaFretesManual = useCallback((pedido: GetPedido) => {
    if (!pedido) {
      return null
    }

    setPedidoEdicao(pedido)
  }, [])

  const handleCallbackSolicitacao = useCallback((solicitacao: any) => {
    if (!solicitacao) {
      return null
    }

    setSolicitacaoEdicao(solicitacao)
  }, [])

  const handleOnSaveFretesManual = async () => {
    if (!pedidoEdicao) {
      return null
    }

    const refresh = () => {
      reloadList()
      setShowListaFretesManual(false)
    }

    const params = {
      body: {
        ...pedidoEdicao,
        id: pedidoEdicao.id
      }
    }

    const atualizarpedido = makeAtualizarPedido()
    await executeUseCase<PostParams>(
      atualizarpedido,
      params,
      'Pedido Alterardo',
      'Salvar frete?',
      () => { refresh() })
  }

  const handleOnSaveSolicitacao = async () => {
    if (!solicitacaoEdicao) {
      return null
    }

    const refresh = () => {
      reloadList()
      setShowSolicitacoes(false)
    }

    const params = {
      body: {
        ...solicitacaoEdicao,
        id: pedidoIdEmEdicao
      }
    }

    await executeUseCase<PostParams>(
      makeNovaSolicitacao(),
      params,
      'Solicitação adicionada',
      'Deseja adicionar esta solicitação?',
      () => { refresh() })
  }

  return (
    <Container>
      {(loadingPedido || executingAtualizaPedido) && <Loading />}
      <PedidosAbertos>
        {/*   tab fixa */}
        <Tab title="Pedidos"
          selected={atendimento.pedidoEmFoco === null}
          onClick={() => handleTabOnClick(null)} />
        {/*   tab fixa */}

        {/*  novo pedido */}
        {!!atendimento?.pedidoEmAndamento?.pedido && (
          <Tab
            title="Novo orçamento"
            selected={atendimento.pedidoEmFoco === 'em_andamento'}
            showCloseButton
            mode="danger"
            onClick={() => handleTabOnClick('em_andamento')}
            onClose={() => handleTabCloseOnClick('em_andamento')}
          />
        )}

        {/* pedidos abertos */}
        {Object.keys(atendimento?.pedidosEmAberto?.data).map((key) => {
          const pedidoId = parseInt(key)
          return (
            <Tab
              key={key}
              title={key}
              selected={atendimento?.pedidoEmFoco === pedidoId}
              showCloseButton
              mode={atendimento?.pedidosEmAberto.data[pedidoId].emEdicao && Object.keys(atendimento?.pedidosEmAberto.data[pedidoId].emEdicao).length ? 'danger' : 'normal'}
              onClick={() => handleTabOnClick(pedidoId)}
              onClose={() => handleTabCloseOnClick(pedidoId)}
            />
          )
        })}

      </PedidosAbertos>
      <Content>
        {atendimento.pedidoEmFoco === null && (
          <TabFixaPedidos>
            <HeaderTablePedidos>
              <div>
                <Button mode='secondary' startIcon={BiFilter} onClick={handleFilterOpen}>Filtros</Button>
                <Button mode='primary' startIcon={FaSearch} onClick={handleSearch}>Pesquisar</Button>
              </div>
              {filterIsOpen &&
                <FiltroPedidos setFilters={setFilters} filters={filters} widthTotal='100%' widthCampos='15%' handleEnterInput={handleSearch} />
              }
              <div>
                <Button
                  startIcon={FaPlus}
                  mode="confirm"
                  onClick={handleClickDropList}
                  data-cy='novoOrcamentoButton'
                >Novo orçamento</Button>
                {dropIsOpen && <DropList callbackClose={handleClickDropList}>
                  <ul>
                    <li onClick={() => handleNovoOrcamentoOnClick('normal')} data-cy='novoOrcamentoNormalLi'>Normal</li>
                    {cliente.is_revenda === 's' && <li onClick={() => handleNovoOrcamentoOnClick('direto')} data-cy='novoOrcamentoDiretoLi'>Faturamento Direto</li>}
                    {cliente.is_revenda === 's' && <li onClick={() => handleNovoOrcamentoOnClick('projeto_solar')} data-cy='novoOrcamentoNormalSolarLi'>Normal - Solar</li>}
                    {cliente.is_revenda === 's' && <li onClick={() => handleNovoOrcamentoOnClick('projeto_direto_solar')} data-cy='novoOrcamentoDiretoSolarLi'>Faturamento Direto - Solar</li>}
                  </ul>
                </DropList>}
              </div>
            </HeaderTablePedidos>
            <Table
              columns={columns}
              showPageJump={false}
              data={pedidos}
              pageSize={perPage}
              page={currentPage}
              pages={pedidosCliente?.metadata?.count && Math.ceil(pedidosCliente?.metadata?.count / perPage)}
              manual
              loading={loadingListaPedido}
              sortable={true}
              onSortedChange={handleOnSortedChange}
              nextText="Próximo"
              previousText="Anterior"
              pageText="Página"
              ofText="de"
              showPageSizeOptions={false}
              noDataText="Nenhum pedido encontrado"
              showPagination={!!pedidosCliente?.metadata?.count && pedidosCliente?.metadata?.count >= perPage}
              dbClickCallback={handleAbrirPedido}
              selectRow
              onPageChange={handleOnPageChange}
            />
          </TabFixaPedidos>
        )}
        {atendimento.pedidoEmFoco === 'em_andamento'
          ? <Pedido mode="register" cliente={atendimento.cliente} handleReloadOnClose={reloadList} handleCancelar={handleTabCloseOnClick}></Pedido>
          : Object.keys(atendimento?.pedidosEmAberto?.data).map(key => {
            const pedidoId = parseInt(key)

            if (atendimento.pedidoEmFoco === pedidoId) {
              return <Pedido mode="edit" cliente={atendimento.cliente} orcamentoIdEmEdicao={pedidoId} handleReloadOnClose={reloadList} handleCancelar={handleTabCloseOnClick}></Pedido>
            }
          })
        }
      </Content>

      {showDesmembrarPedido && <Modal title="Desmembrar Pedido"
        close={() => setShowDesmembrarPedido(false)}
        buttonSaveText='Salvar'
        showButtonSave={true}
        closeOnSave={false}
        onSave={handleOnSaveDesmembrarPedido} >
        <DesmembrarPedido pedidoId={pedidoIdEmEdicao} callBack={handleCallbackDesmembrarPedido} />
      </Modal>}

      {showListaFretes && <Modal title="Fretes" close={() => setShowListaFretes(false)} >
        {loadingPedidoFretes ? <Loading /> : ''}
        <ListaFretes pedido={pedidoFretes} callBack={handleCallbackListaFretes} />
      </Modal>}

      {showListaFretesManual && <Modal
        title="Frete Manual"
        close={() => setShowListaFretesManual(false)}
        buttonSaveText='Salvar'
        showButtonSave={true}
        closeOnSave={false}
        onSave={handleOnSaveFretesManual}
      >
        <ListaFretesManual pedidoId={pedidoIdEmEdicao} callBack={handleCallbackListaFretesManual} />
      </Modal>}

      {showSolicitacoes && <Modal title="Solicitação"
        close={() => setShowSolicitacoes(false)}
        buttonSaveText='Salvar'
        showButtonSave={true}
        closeOnSave={false}
        onSave={handleOnSaveSolicitacao} >
        <NovaSolicitacao pedidoId={pedidoIdEmEdicao} callBack={handleCallbackSolicitacao} />
      </Modal>}

    </Container>
  )
}
