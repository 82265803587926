import React, { useState, useMemo } from 'react'
import { FaBox, FaBullhorn, FaCodeBranch, FaCopy, FaFilePdf, FaFolderOpen, FaHistory, FaLevelUpAlt, FaList, FaLock, FaLockOpen, FaTimes, FaTruck } from 'react-icons/fa'
import { BsThreeDots } from 'react-icons/bs'
import { FiSlash } from 'react-icons/fi'
import { ButtonTable, ListaEstoquesAlternativos, ListaHistorico, ResumoPedido } from '..'
import { GetPedido } from '../../../modules/pedidos/models'
import { Container, ListaAcoes, LiAcoes, LiDrop, UlAcoes } from './styles'
import { Modal } from '../Modal'
import Swal from 'sweetalert2'
import { useEstoquesAlternativos } from '../../hooks'
import { NovaSolicitacao } from '../NovaSolicitacao'

interface AcoesPedidosProps {
  pedido: GetPedido
  callbackOpen: (pedido: GetPedido) => void
  handleProcessarFrete: (pedido: GetPedido) => void
  handleProcessarFreteEstacionamento: (pedido: GetPedido) => void
  handleLiberarEstoque: (pedido: GetPedido, valor: string) => void
  handleGerarPdf: (pedido: GetPedido) => void
  handleTransformarPedido: (pedido: GetPedido) => void
  handleDesmembrarPedido: (pedido: GetPedido) => void
  handleCancelarPedido: (pedido: GetPedido) => void
  handleBloquearPedido: (pedido: GetPedido, modo: string) => void
  handleSolicitacoes: (pedido: GetPedido) => void
}

export const AcoesPedidos = (props: AcoesPedidosProps) => {
  const [openList, setOpenList] = useState(false)
  const [openCopiar, setOpenCopiar] = useState(false)
  const [openFrete, setOpenFrete] = useState(false)
  const [openBloquear, setOpenBloquear] = useState(false)
  const [modalResumoOpen, setModalResumoOpen] = useState(false)
  const [modalHistoricoOpen, setModalHistoricoOpen] = useState(false)
  const [modalEstoqueAlternativoOpen, setModalEstoqueAlternativoOpen] = useState(false)
  const {
    fetcher: getEstoquesAlternativos,
    data: listaEstoqueAlternativo,
    loading: loadingEstoqueAlternativo
  } = useEstoquesAlternativos()

  const bloqueios = useMemo(() => {
    return {
      estoque_zerado: props.pedido?.estoque_zerado,
      pedido_desbloqueado: props.pedido?.pedido_desbloqueado,
      liberacao_bloqueada: props.pedido?.liberacao_bloqueada
    }
  }, [props.pedido])

  const showBloqueio = () => {
    if (bloqueios.estoque_zerado === 'z' || bloqueios.pedido_desbloqueado === 's') {
      return true
    } else {
      return false
    }
  }

  const handleLiberarEstoque = (valor: string) => {
    if (bloqueios.liberacao_bloqueada === 's' || props.pedido.bloqueio_lucro_minimo === 'bloqueado') {
      let message = ''
      if (bloqueios.liberacao_bloqueada === 's') {
        message = 'Estoque já esta liberando o pedido.'
      } else if (props.pedido.bloqueio_lucro_minimo === 'bloqueado') {
        message = 'Pedido com lucro mínimo bloqueado'
      }

      Swal.fire({
        icon: 'warning',
        title: 'Pedido Bloqueado!',
        text: message,
        timer: 2500,
        timerProgressBar: true
      })
      return
    }
    setOpenList(false)
    props.handleLiberarEstoque(props.pedido, valor)
  }

  const handleOpen = (pedido: GetPedido) => {
    setOpenList(false)
    props.callbackOpen(pedido)
  }

  const handleResumoButton = () => {
    setOpenList(false)
    setModalResumoOpen(true)
  }

  const handleHistoricoButton = () => {
    setOpenList(false)
    setModalHistoricoOpen(true)
  }

  const handleGerarPdf = () => {
    if (
      props.pedido.estoque_zerado === 's' ||
      (props.pedido.pedido_desbloqueado === 'n' && props.pedido.status === 'bloqueado') ||
      props.pedido.solicitacao_vendedor_status === 'rejeitado' ||
      props.pedido.solicitacao_vendedor_status === 'pendente' ||
      props.pedido.bloqueio_lucro_minimo === 'bloqueado') {
      let message = ''
      if (props.pedido.estoque_zerado === 's') {
        message = 'Pedido com estoque zerado'
      } else if (props.pedido.pedido_desbloqueado === 'n' && props.pedido.status === 'bloqueado') {
        message = 'Pedido bloqueado'
      } else if (props.pedido.solicitacao_vendedor_status === 'rejeitado' || props.pedido.solicitacao_vendedor_status === 'pendente') {
        message = 'Pedido com solicitação rejeitado ou pendente'
      } else if (props.pedido.bloqueio_lucro_minimo === 'bloqueado') {
        message = 'Pedido com lucro mínimo bloqueado'
      }
      Swal.fire({
        icon: 'warning',
        title: 'Gerar PDF indisponível!',
        text: message,
        showConfirmButton: true,
        confirmButtonText: 'Ok'
      })
      return
    }
    setOpenList(false)
    props.handleGerarPdf(props.pedido)
  }

  const handleCancelarPedido = () => {
    setOpenList(false)
    if ((props.pedido.valor_adiantamento && props.pedido.valor_adiantamento > 0) ||
      props.pedido.status_liberacao === 'parcial' ||
      props.pedido.liberado === 's' ||
      props.pedido.liberacao_bloqueada === 's'
    ) {
      let mensagem = ''
      if (props.pedido.valor_adiantamento && props.pedido.valor_adiantamento > 0) {
        mensagem += 'Pedido possui Adiantamento gerado <br>'
      }
      if (props.pedido.status_liberacao === 'parcial') {
        mensagem += 'Pedido parcialmente liberado <br>'
      }
      if (props.pedido.liberado === 's') {
        mensagem += 'Pedido ja foi liberado para estoque<br>'
      }
      if (props.pedido.liberacao_bloqueada === 's') {
        mensagem += 'Estoque está liberando pedido <br>'
      }
      Swal.fire({
        icon: 'warning',
        title: 'Ação Invalida',
        html: mensagem,
        showConfirmButton: true,
        confirmButtonText: 'Ok'
      })
    } else {
      props.handleCancelarPedido(props.pedido)
    }
  }

  const handleBloquearPedido = (modo: string) => {
    setOpenBloquear(false)
    setOpenList(false)
    props.handleBloquearPedido(props.pedido, modo)
  }

  const handleTransformarPedido = () => {
    setOpenList(false)
    props.handleTransformarPedido(props.pedido)
  }

  const handleCopyText = (text?: string | number) => {
    setOpenCopiar(state => !state)
    setOpenList(false)
    if (text) {
      navigator.clipboard.writeText(text.toString())
      Swal.fire({
        icon: 'success',
        title: 'Copiado!',
        position: 'top-end'
      })
    }
  }

  const handleProcessarFrete = (pedido: GetPedido) => {
    setOpenList(false)

    if (pedido) {
      props.handleProcessarFrete(pedido)
    }
  }

  const handleProcessarFreteEstacionamento = (pedido: GetPedido) => {
    setOpenList(false)

    if (pedido) {
      props.handleProcessarFreteEstacionamento(pedido)
    }
  }

  const handleDesmembrarPedido = (pedido: GetPedido) => {
    setOpenList(false)

    if (pedido) {
      props.handleDesmembrarPedido(pedido)
    }
  }

  const handleEstoqueAlternativo = (pedido: GetPedido) => {
    setOpenList(false)
    if (pedido) {
      getEstoquesAlternativos(pedido.id as number)
      setModalEstoqueAlternativoOpen(true)
    }
  }

  const handleNotificacoes = (pedido: GetPedido) => {
    setOpenList(false)
    if (pedido) {
      props.handleSolicitacoes(pedido)
    }
  }

  return (
    <Container>
      <ButtonTable
        title='Ações'
        type="button"
        typeButton='primary'
        onClick={() => setOpenList(state => !state)}
      ><BsThreeDots />
      </ButtonTable>
      {openList && <Modal close={() => setOpenList(false)} title='Ações do Pedido' mode='mini'>
        <ListaAcoes>
          <UlAcoes>
            <LiAcoes onClick={() => handleOpen(props.pedido)}><FaFolderOpen size='15px' /><span>Abrir Pedido</span></LiAcoes>
            {showBloqueio() && <LiAcoes onClick={() => setOpenBloquear(state => !state)}><FiSlash size='15px' /><span>Bloquear</span></LiAcoes>}
            {openBloquear && <LiDrop>
              <UlAcoes>
                {bloqueios.estoque_zerado === 'z' && <LiAcoes onClick={() => handleBloquearPedido('estoque_zerado')}><FaCopy size='15px' /><span>Estoque Zerado</span></LiAcoes>}
                {bloqueios.pedido_desbloqueado === 's' && <LiAcoes onClick={() => handleBloquearPedido('desconto')}><FaCopy size='15px' /><span>Desconto</span></LiAcoes>}
              </UlAcoes>
            </LiDrop>}
            <LiAcoes onClick={handleCancelarPedido}>
              <FaTimes size='15px' />
              <span>{props.pedido.tipo === 'orçamento' ? 'Cancelar Orçamento' : 'Cancelar Pedido'}</span>
            </LiAcoes>
            <LiAcoes onClick={() => setOpenCopiar(state => !state)}><FaCopy size='15px' /><span>Copiar</span></LiAcoes>
            {openCopiar && <LiDrop>
              <UlAcoes>
                <LiAcoes onClick={() => handleCopyText(props.pedido.cliente?.id)}><FaCopy size='15px' /><span>Código do Cliente</span></LiAcoes>
                <LiAcoes onClick={() => handleCopyText(props.pedido.id)}><FaCopy size='15px' /><span>Código do Pedido</span></LiAcoes>
                {props.pedido.linha_digitavel !== '' && <LiAcoes onClick={() => handleCopyText(props.pedido.linha_digitavel)}>
                  <FaCopy size='15px' /><span>Linha Digitavel</span>
                </LiAcoes>}
                {props.pedido.link_pagamento !== '' && <LiAcoes onClick={() => handleCopyText(props.pedido.link_pagamento)}>
                  <FaCopy size='15px' /><span>Link Pagamento</span>
                </LiAcoes>}
                {props.pedido.link_pagamento_pix !== '' && <LiAcoes onClick={() => handleCopyText(props.pedido.link_pagamento_pix)}>
                  <FaCopy size='15px' /><span>Pix</span>
                </LiAcoes>}
              </UlAcoes>
            </LiDrop>}
            { props.pedido.liberado === 'n' && (
              <>
                <LiAcoes onClick={() => setOpenFrete(state => !state)}><FaTruck size='15px' /><span>Processar Frete</span></LiAcoes>
                {openFrete && <LiDrop>
                  <UlAcoes>
                    <LiAcoes onClick={() => handleProcessarFrete(props.pedido)}> <FaTruck size='15px' /><span>Transportadora</span></LiAcoes>
                    <LiAcoes onClick={() => handleProcessarFreteEstacionamento(props.pedido)}> <FaTruck size='15px' /><span>Estacionamento</span></LiAcoes>
                  </UlAcoes>
                </LiDrop>}
              </>
            )}
            <LiAcoes onClick={() => handleEstoqueAlternativo(props.pedido)}> <FaBox size='15px' /><span>Estoque Alternativo</span></LiAcoes>
            <LiAcoes onClick={() => handleNotificacoes(props.pedido)}> <FaBullhorn size='15px' /><span>Solicitações</span></LiAcoes>
            {props.pedido.tipo === 'pedido' && <>{props.pedido.liberado === 's'
              ? <LiAcoes onClick={() => handleLiberarEstoque('n')}> <FaLock size='15px' /><span>Estoque Aguardar</span></LiAcoes>
              : <LiAcoes onClick={() => handleLiberarEstoque('s')}> <FaLockOpen size='15px' /><span>Estoque Liberar</span></LiAcoes>
            }</>}

            <LiAcoes onClick={() => handleDesmembrarPedido(props.pedido)}> <FaCodeBranch size='15px' /><span>Desmembrar Pedido</span></LiAcoes>

            {(props.pedido.status !== 'bloqueado' || props.pedido.estoque_zerado !== 's') && <LiAcoes onClick={handleGerarPdf}><FaFilePdf size='15px' /><span>Gerar Pdf</span></LiAcoes>}
            <LiAcoes onClick={handleHistoricoButton}><FaHistory size='15px' /><span>Historico</span></LiAcoes>
            <LiAcoes onClick={handleResumoButton}><FaList size='15px' /><span>Resumo</span></LiAcoes>
            {(props.pedido.tipo === 'orçamento' || props.pedido.tipo === 'orcamento') && props.pedido.status !== 'cancelado' &&
              <LiAcoes onClick={handleTransformarPedido}>
                <FaLevelUpAlt size='15px' />
                <span>Transformar em Pedido</span>
              </LiAcoes>
            }
          </UlAcoes>
        </ListaAcoes>
      </Modal>
      }
      {modalHistoricoOpen && <Modal close={() => setModalHistoricoOpen(false)} mode='fullscreen' title='Histórico do Pedido'>
        <ListaHistorico historico={props.pedido.historico} />
      </Modal>
      }

      {modalResumoOpen && <Modal close={() => setModalResumoOpen(false)} mode='fullscreen' title='Resumo do Pedido'>
        <ResumoPedido pedidoId={props.pedido.id} />
      </Modal>
      }

      {modalEstoqueAlternativoOpen &&
        <Modal
          close={() => setModalEstoqueAlternativoOpen(false)}
          mode='normal'
          title='Estoques Alternativos'
        >
          <ListaEstoquesAlternativos estoques={listaEstoqueAlternativo} loading={loadingEstoqueAlternativo} />
        </Modal>
      }

    </Container>
  )
}
