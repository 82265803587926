import { UpdateParams, Validator } from '../../_interfaces'
import { AtualizarClienteService } from '../contracts'
import { Cliente } from '../models'

export class AtualizarCliente {
  private readonly atualizarClienteService: AtualizarClienteService
  private readonly validator: Validator

  constructor (atualizarAtualizarClienteService: AtualizarClienteService, validator: Validator) {
    this.atualizarClienteService = atualizarAtualizarClienteService
    this.validator = validator
  }

  async execute (params: UpdateParams): Promise<Cliente> {
    await this.validator.validate(params.body)
    const response = await this.atualizarClienteService.atualizarCliente(params)
    return response
  }
}
